<template>
  <div class="reserve-edit-draw">
    <el-dialog class="edit-draw-content" @close="changedDrawer" v-model="pageDialogVisible" :width="600" align-center>
      <div ref="pageDraw" class="top-form">
        <div class="left">预约详情
        </div>
        <div class="right">
          <template v-if="reserveInfo.appStatus == 1">
            <div class="ca-tag1">
              <el-tag size="small" effect="plain" class="ml-2">待到店</el-tag>
            </div>
          </template>
          <template v-if="reserveInfo.appStatus == 2">
            <div class="ca-tag2">
              <el-tag size="small" effect="plain" class="ml-2">已到店</el-tag>
            </div>
          </template>
          <template v-if="reserveInfo.appStatus == 3">
            <div class="ca-tag3">
              <el-tag size="small" effect="plain" class="ml-2">已开单</el-tag>
            </div>
          </template>
          <template v-if="reserveInfo.appStatus == 4"><span class="el-tag el-tag--danger el-tag--large el-tag--plain">
              已完成</span></template>
          <template v-if="reserveInfo.appStatus == 5"><span class="el-tag el-tag--warning el-tag--large el-tag--plain">已取消</span></template>
          <template v-if="reserveInfo.appStatus == 6"><span class="el-tag el-tag--danger el-tag--large el-tag--plain">已退款</span></template>
        </div>
      </div>
      <div class="title reserve-people-title">预约信息
      </div>
      <div class="reserve-people">
        <div class="item">
          <div class="key">预约单号:</div>
          <el-link @click="handleReserveDetailcheck()" type="primary">{{reserveInfo.appNo}}</el-link>
        </div>
        <div class="item">
          <div class="key">下单人</div>
          <div class="value">
            <el-link @click="handleUserDetailcheck()" type="primary" class="uname">{{reserveInfo.userName}}</el-link>
            <div class="phone">+86 {{ reserveInfo.phone }}</div>
          </div>
        </div>
      </div>
      <div class="shop-info">
        <div class="item">
          <div class="key">预约下单时间:</div>
          <div class="value">{{ reserveInfo.appTime }}</div>
        </div>
        <div class="item">
          <div class="key">预约门店:</div>
          <div class="value">{{ reserveInfo.shopName }}</div>
        </div>
        <div class="item">
          <div class="key">房间与床位:</div>
          <div class="value">
            <el-select :disabled="reserveInfo.appStatus > 2" @change="handleRoomChange" size="small" v-model="ruleForm.roomId" class="m-2" placeholder="选择房间">
              <el-option v-for="item in shopRoomList" :key="item.roomId" :label="item.roomNo" :value="item.roomId" :disabled="!item.enabled" />
            </el-select>
            <el-select @change="handleRoomBedChange" :disabled="String(ruleForm.roomId).length == 0 || reserveInfo.appStatus > 2" size="small" v-model="ruleForm.bed" class="m-2" placeholder="选择床位">
              <el-option v-for="item in roomBedList" :key="item.bedId" :label="item.bedId" :value="item.bedId" :disabled="!item.enabled" />
            </el-select>
          </div>
        </div>
        <div class="item">
          <div class="key">预约时间:</div>
          <div class="value">
            <template v-if="!(reserveInfo.appStatus >= 2)">
              <el-date-picker :clearable="false" :disabled-date="disabledDate" size="small" v-model="ruleForm.arriveDate" type="date" placeholder="预约时间" />

              <el-select @change="handleTimeChange" size="small" v-model="ruleForm.arriveTime" placeholder="选择时间">
                <el-option v-for="item in userTimeList" :disabled="!item.isDisplay||item.timeStatus==2" :key="item.time" :label="item.time" :value="item.time" />
              </el-select>
              <!-- <el-time-select :clearable="false" @change="handleTimeChange" size="small" :start="timeStart" end="23:40"
                v-model="ruleForm.arriveTime" step="00:10" placeholder="选择时间" /> -->
            </template>
            <template v-else>
              <div>
                <el-date-picker disabled size="small" v-model="ruleForm.arriveDate" type="date" />
                <el-time-select disabled start="07:00" end="23:40" size="small" v-model="ruleForm.arriveTime" step="00:10" />
              </div>
            </template>
          </div>
        </div>
      </div>
      <el-divider class="border-divider" />
      <div class="title">服务信息</div>
      <div class="opt-teacher">
        <div class="key">理疗师:</div>
        <el-select @change="handleTeacherChange" class="teacher" v-model="reserveForm.empId" placeholder="选择理疗师" size="small" :disabled="reserveInfo.appStatus == 4 || reserveInfo.appStatus == 6">
          <el-option @click="handleOptTeacherClick(item)" v-for="item in shopTeacherList" :key="item.userId" :label="item.userRealname" :value="item.userId" />
        </el-select>
      </div>
      <div class="serve-info">
        <div class="titles">
          <div class="project">服务项目:</div>
          <div class="time">服务时间</div>
          <div class="time">筹备时间</div>
          <!-- <div class="teacher">理疗师:</div> -->
          <div class="handle" v-if="reserveInfo.appStatus != 4 && reserveInfo.appStatus != 6">操作</div>
        </div>
        <template v-for="(buyItem, buyItemIndex) in serveNameArr" :key="buyItem">
          <div class="item">
            <div class="key">
              {{ buyItem.name }}
            </div>
            <div class="serveTimeLength">
              {{ buyItem.serveTimeLength }}分钟
            </div>
            <div @click="handleRemoveProject(buyItemIndex)" class="handle" v-if="reserveInfo.appStatus != 4 && reserveInfo.appStatus != 6">删除</div>
          </div>
        </template>

        <serveList :currentOptTeacherName="currentOptTeacherName" @optProjectChange="handleOptProjectChange" :popoverWidth="popoverWidth" @handleServeTypeChange="handleServeTypeChange" :teacherServeCategoryList="teacherServeCategoryList" :reserve="reserveInfo" :shopId="reserveForm.shopId" :empId="reserveForm.empId">
        </serveList>
        <!-- <div class="save-btn">
          <el-button @click="handleUpdateReserveProject" color="#7948EA" type="primary" size="small">保存</el-button>
        </div> -->
      </div>
      <div style="display:flex">
        <div style="flex:1">
          <div class="title">服务时长</div>
          <div class="follow">
            <el-select class="teacher" v-model="ruleForm.totalTime" placeholder="选择服务时长" size="large" @change="handleServeTimeChange" :disabled="reserveInfo.appStatus > 2">
              <el-option v-for="(item,index) in totalTimeList" :key="index" :label="item" :value="item" />
            </el-select>
          </div>
        </div>
        <div style="flex:1">
          <div class="title">筹备时长</div>
          <div class="follow" style="display:flex;align-items: center;">
            <el-select class="teacher" v-model="ruleForm.prepare" placeholder="选择服务时长" size="large" @change="handleServeTimeChange" :disabled="reserveInfo.appStatus > 2">
              <el-option v-for="(item,index) in totalTimeList2" :key="index" :label="item" :value="item" />
            </el-select>
            <p style="margin-left:10px;">分钟</p>
          </div>
        </div>
      </div>

      <div class="title">备注</div>
      <div class="follow">
        <el-input show-word-limit v-model="ruleForm.remark" :maxlength="100" :rows="2" type="textarea" placeholder="请输入备注信息" :disabled="reserveInfo.appStatus > 2"/>
        <!-- <div class="submin-btn">
          <el-button size="small" plain @click="handleSubmitClick('remark')">提交</el-button>
        </div> -->
        <div class="list">
          <!-- <div class="titles">
            <div class="comment">备注内容</div>
            <div class="teacher">理疗师</div>
          </div> -->
          <div class="list-item">
            <template :key="item" v-for="item in reserveRemarkList">
              <div class="follow-item">
                <div class="top">
                  <div class="left">
                    <div class="memo">{{ item.memo }}</div>
                  </div>
                  <div class="right">
                    <div class="name">
                      {{ item.userRealname }}
                    </div>
                  </div>
                </div>
                <div class="bottom">
                  <div class="time">{{ item.createTime }}</div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>

      <div class="save-btn">
        <el-button color="#D14B66" v-has="'board:edit'" @click="handleSaveClick" type="primary" style="margin-right: 50px;" :loading="loading">保存</el-button>
        <el-button color="#383838" v-has="'board:delete'" @click="handleCancelReserve" v-if="reserveInfo.appStatus <= 2" class="cancel" type="info">
          取消预约</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, watch, onMounted, onUnmounted, nextTick } from 'vue'
import PageDraw from '@/components/page-drawer/page-drawer.vue'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import { useState } from '@/hooks'
import { UPLOAD_BASE_URL } from '@/common/constant'
import { categoryProps } from './config'
import { useRouter } from 'vue-router'
import emitter from '@/utils/eventbus'
import dayjs from 'dayjs'
import { ElMessage } from 'element-plus'
import { popup } from '@/utils/pop-up'
import { getOrderDetail } from '@/service/main/order'
import { updateReserveProject, getTeacheriSAvaiable, getHasTimeServeTeacherList, updateReserveDate, newGetTeacheriSAvaiable, setReserveRemark, addCustomerInfo } from '@/service/main/reserve'
import addReserveDialog from '@/views/main/reserve/check/cpns/base-ui/add-reserve-dialog/add-reserve-dialog.vue'
import serveList from './cpns/serve-list/serve-list.vue'
import { getBedListByApp,getShopRoomList, getServeCategoryList, updateReserveRoomBed, updateReserve, getReserveShopList, removeTeacherTime, newCancelorder,getUserTimeList } from '@/service/main/reserve'
import cache from '@/utils/cache'

export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    reserve: {
      type: Object,
      default () {
        return {}
      }
    },
    shopTeacherListData: {
      type: Array,
      default () {
        return []
      }
    },
    isCheck: {
      type: Boolean,
      default: false
    }
  },
  components: {
    PageDraw,
    addReserveDialog,
    serveList
  },
  emits: ['changedDrawer', 'refreshPage'],
  setup (props, { emit }) {
    const store = useStore()
    const router = useRouter()
    const pageDialogVisible = computed(() => props.dialogVisible)
    const reserveInfo = computed(() => props.reserve)
    const shopTeacherList = computed(() => props.shopTeacherListData)
    const serveNameArr = ref([])
    const userTimeList = ref([])
    const canRooms =  ref([])
    const loading = ref(false)
    const totalTimeList = reactive([
      '10','20','30','40','50','60','70','80','90','100','110','120','130','140','150','160','170','180'
    ])
    const totalTimeList2 = reactive([
    '0','10','20','30','40'])
    const changedDrawer = (flag) => {
      emit('changedDrawer', flag)
    }

    // emitter.on('changeReserveEditDrawPageContent', () => {
    // })


    const teacherServeCategoryList = ref([{
      kindId: '',
      kindName: '所有分类'
    }])
    const getServeCatewgory = async () => {
      const res = await getServeCategoryList(COMPANY_ID)
      let list = [...teacherServeCategoryList.value, ...res.data.list]
      teacherServeCategoryList.value = list

    }
    getServeCatewgory()

    emitter.on('refreshReserveRemarkListContent', (orderNo) => {
      store.dispatch('reserve/getReserveRemarkListAction', orderNo)
    })

    emitter.on('refreshReserveCustomerListContent', userId => {
      store.dispatch('reserve/getCustomerInfoListAction', userId)
    })

    const handleEditClick = () => {

    }

    const shopRoomList = ref([])
    const roomBedList = ref([])
    const teacherList = computed(() => store.state.reserve.teacherList)
    const reserveRemarkList = computed(() => store.state.reserve.reserveRemarkList)
    const customerInfoList = computed(() => store.state.reserve.customerInfoList)

    const ruleForm = reactive({
      toShopPreson: 1,
      nickname: '',
      roomId: '',
      arriveDate: new Date(),
      arriveTime: '',
      teacxherId: '',
      // followInfo: '',
      remark: '',
      totalTime: "",
      bed: '',
      prepare:'',
      arvPerson: ""
    })


    

    const emptyForm = () => {
      for (let key in ruleForm) {
        if (key != 'toShopPreson') {
          ruleForm[key] = ''
        } else {
          ruleForm[key] = 1
        }
      }
    }

    const timeStart = computed(() => {
      let curDay = dayjs().format('YYYY-MM-DD')
      let optDay = dayjs(ruleForm.arriveDate).format('YYYY-MM-DD')
      let optDate, curDate, mm, HH
      if (curDay == optDay) {
        optDate = dayjs().valueOf()
        mm = dayjs().format('mm')
        HH = dayjs().format('HH')
      } else {
        optDate = dayjs(ruleForm.arriveDate).valueOf()
        mm = dayjs(ruleForm.arriveDate).format('mm')
        HH = dayjs(ruleForm.arriveDate).format('HH')
      }

      curDate = dayjs().valueOf()
      // console.log(ruleForm.arriveDate);
      // console.log(mm);
      // console.log(HH);
      if (optDate - curDate > 0) {
        HH = '07'
        mm = '00'
      } else {
        if (mm > 0 && mm <= 10) {
          mm = '10'
        }
        else if (mm > 10 && mm <= 20) {
          mm = '20'
        }

        else if (mm > 20 && mm <= 30) {
          mm = '30'
        }

        else if (mm > 30 && mm <= 40) {
          mm = '40'

        }
        else if (mm > 40 && mm <= 50) {
          mm = '50'

        }
        else if (mm > 50 && mm <= 59) {
          mm = '00'
          HH = Number(HH) + 1
        }
      }

      let time = String(HH) + ':' + String(mm)
      // console.log(time);
      return time

    })
    // 查看订单点击事件
    const handleReserveDetailcheck = () => {
      router.push({
        path: '/main/order/detail',
        query: {
          type: 1,
          id: reserveInfo.value.orderId
        }
      })
    }
    //查看会员详情
    const handleUserDetailcheck = () =>{
      let { userId, phone } = reserveInfo.value
      router.push({
        path: "/main/customer/detail",
        query: {
          userId,
          phone: phone
        }
      })
    }

    const handleSaveClick = async () => {
      loading.value = true
      let info = reserveInfo.value
      let { id,userId} = info
      let servers = []
      reserveForm.reserveArr.forEach(item=>{
        servers.push({
          serveId: item.serveId,
          count: 1
        })
      })
      let obj = {
        id,
        userId,
        date: dayjs(ruleForm.arriveDate).format('YYYY-MM-DD'),
        time: ruleForm.arriveTime,
        empId: reserveForm.empId,
        itemList: servers,
        roomId:ruleForm.roomId,
        bed:ruleForm.bed,
        serveTimeLength:ruleForm.totalTime,
        prepare:ruleForm.prepare,
        remark:ruleForm.remark,
        shopId:reserveForm.shopId
      }
      const updateRes = await updateReserve(obj)
      if (updateRes.code == 0) {
        changedDrawer(false)
        emit('refreshPage')
        ElMessage({
          message: '操作成功!',
          type: 'success',
        })
      }else{
        ElMessage({
          message: updateRes.msg,
          type: 'err',
        })
      }
      setTimeout(()=>{
        loading.value = false
      },1500)
    }

    const handleSubmitClick = async () => {
      if (ruleForm.remark.length > 0) {
        let obj = {
          orderNo: reserveInfo.value.orderNo,
          memo: ruleForm.remark,
          userId: reserveInfo.value.empId
        }
        await setReserveRemark(obj)  // TODO:刷新
        ruleForm.remark = ''
      }

      // if (ruleForm.followInfo.length > 0) {
      //   let obj = {
      //     orderNo: reserveInfo.value.orderNo,
      //     memo: ruleForm.followInfo,
      //     userId: reserveInfo.value.userId,
      //     empId: reserveInfo.value.empId
      //   }
      //   await addCustomerInfo(obj)  // TODO:刷新
      //   ruleForm.followInfo = ''
      // }

    }

    const handleRemoveItemClick = (id, type) => {
      if (type == 'remark') {
        store.dispatch('reserve/removeReserveRemarkAction', [id, reserveInfo.value.orderlist[0].orderNo])
      } else {
        store.dispatch('reserve/removeCustomerInfoAction', {
          id,
          orderNo: reserveInfo.value.orderlist[0].orderNo,
          userId: reserveInfo.value.userId
        })
      }

    }

    const handleCancelReserve = () => {
      const { id } = reserveInfo.value
      popup('确定取消预约?', () => {
        newCancelorder(id).then(() => {
          ElMessage({
            message: '操作成功!',
            type: 'success'
          })
          emit('refreshPage')
          changedDrawer(false)
        })
      })
    }

    const isFirstRoomChange = ref(true)

    const handleRoomChange = value => {
      // if (!isFirstRoomChange.value) {
      //   ruleForm.bedId = ''
      // } else {
      //   isFirstRoomChange.value = false
      // }

      const ele = shopRoomList.value.find(ele => ele.roomId == value)
      if (ele != undefined) {
        let arr = []
        let bedArr = ele.bedList
        for (let i = 0; i < bedArr.length; i++) {
          arr.push({
            ...bedArr[i],
            bedId:String(bedArr[i].bedId)
          })
        }
        ruleForm.bed=""
        roomBedList.value = arr
        // console.log(arr)
      }
    }


    const disabledDate = (time) => {
      return time.getTime() < Date.now() - 8.64e7
    }

    const addReserveDialogVisible = ref(false)
    const changeAddReserveDialog = flag => {
      addReserveDialogVisible.value = flag
    }
    const currentStaffIndex = ref(1)
    const addInfo = ref()
    const handleAddReserveClick = () => {
      addReserveDialogVisible.value = true
    }

    const handleUpdateReserveProject = async () => {
      let info = reserveInfo.value
      let { arriveDate, arriveTime } = ruleForm
      // console.log(updateProjectInfo.lastTime);
      if (String(arriveDate).length == 0 || String(arriveTime).length == 0) {
        ElMessage({
          message: '请选择预约时间!',
          type: 'warning'
        })
        return
      }

      let { empId, shopId, totalTime, reserveArr } = reserveForm
      let { appNo, appStatus, userId, isPoint, arrivePerson } = info
      let { orderNo } = info.orderlist[0]
      let appTimes = [],
        timeList = [], serveArr = [], timeListArr = []
      let startTime = dayjs(`2022-12-12 ${updateProjectInfo.lastTime}`).add(10, 'minute').format('HH:mm')
      arriveDate = dayjs(arriveDate).format('YYYY-MM-DD')
      // console.log(startTime);
      reserveArr.forEach((ele) => {
        let arr = ele.split(',')
        let timeLength = arr[arr.length - 1] / 10
        let timeArr = []
        serveArr.push(`${arr[1]},${arr[2]}`)
        for (let i = 0; i <= timeLength; i++) {
          let time = dayjs(`2018-09-01 ${startTime}`)
            .add(10 * i, 'minute')
            .format('HH:mm')
          timeArr.push(time)
          timeListArr.push(time)
          if (i == timeLength) {
            startTime = dayjs(`2018-09-01 ${time}`)
              .add(10, 'minute')
              .format('HH:mm')
          }
        }
        timeList.push(timeArr.join())
      })
      updateProjectInfo.addTimeArr = timeList
      updateProjectInfo.addTimeList = timeListArr

    }

    const changeTimeCount = ref(0)
    const updateProjectInfo = reactive({
      updateTimeInfo: [],
      lastTime: "",
      updateTimeArr: [],
      addTimeArr: [],
      oldTimeList: [],
      addTimeList: []
    })
    const handleTimeChange = async time => {
      let reserve = reserveInfo.value
      let { timelist, shopId } = reserve
      let { arriveDate } = ruleForm
      let teaRes = await getHasTimeServeTeacherList({
        appdate: dayjs(arriveDate).format('YYYY-MM-DD'),
        paraList: timelist.map(t => t.paraId),
        shopid: shopId,
        time,
      })
      let teacherList = teaRes.data.list
      shopTeacherList.value = teacherList
      // reserveForm.empId = ''
      emitter.emit('emptyReserveOptProjectList')
      // currentOptTeacherName.value = ''
    }


    const handleRemoveProject = async (index) => {
      let info = reserveInfo.value
      let { empId, shopId, appNo, appStatus, userId, isPoint, arrivePerson, arriveDate, orderNo, timelist } = info

      let id = timelist[index].id;
      let delTimeStr = timelist[index].timeStr;
      let arriveTime = timelist[index].arriveTime

      let obj = {
        delIds: id,
        newServeStr: '',
        delTimeStr,
        newTimeStr: "",
        delFlag: 1,
        newFlag: 0,
        empId,
        companyId: COMPANY_ID,
        shopId,
        orderNo,
        appNo,
        arriveDate: dayjs(ruleForm.arriveDate).format('YYYY-MM-DD'),
        arriveTime,
        appStatus,
        userId,
        isPoint,
        arrivePerson
      }
      serveNameArr.value.splice(index, 1)

      // const res = await updateReserveProject(obj)
      // if (res.code == 0) {
      //   changedDrawer(false)
      //   ElMessage({
      //     message: '操作成功!',
      //     type: 'success',
      //   })

      //   serveNameArr.value.splice(index, 1)

      //   emit('refreshPage')
      // } else {
      //   ElMessage({
      //     message: '当前状态不可删除!',
      //     type: 'warning',
      //   })
      // }
    }

    const reserveForm = reactive({
      companyId: COMPANY_ID,
      userId: "",
      shopId: "",
      arriveDate: new Date(),
      arriveTime: "",
      empId: "",
      userMobile: "",
      appMemo: "",
      arriveKind: 1,
      arrivePerson: '',
      reserveLastTime: "24:00",
      cashUid: '',
      serveStr: '',
      totalTime: "",
      reserveArr: [],
      startTime: "",
      orderNo: '',
      reserveId: '',
    })

    const currentOptTeacherName = ref('')
    const handleOptProjectChange = async item => {
        serveNameArr.value=[]
      ruleForm.totalTime=item.totalTime
      ruleForm.prepare=item.prepareTime

      reserveForm.totalTime = item.totalTime
      reserveForm.reserveArr = item.reserveArr
      // handleUpdateReserveProject()
      // // console.log('updateProjectInfo', updateProjectInfo);
      // let { addTimeArr, updateTimeArr } = updateProjectInfo
      // let mainTimeArr = [...updateTimeArr, ...addTimeArr]

      // let { empId } = reserveForm
      // let { arriveDate } = ruleForm
      // let res = await getTeacheriSAvaiable({
      //   appDate: arriveDate,
      //   appTimes: mainTimeArr.join(),
      //   userId: empId
      // })
      //  if(res.data>0){
      //   ElMessage({
      //       message: '当前理疗师时间不可用!',
      //       type: 'warring',
      //     })
      // return
      //  }
    }
    // const shopTeacherList = ref([])
    const pageDraw = ref()
    const popoverWidth = ref(500)
    // const teacherServeList = computed(() => store.state.reserve.teacherServeList)
    const handleServeTypeChange = (value) => { // TODO:
      // console.log('handleServeTypeChange', value)
      // let serveList = teacherServeList.value
      // if (value.length == 0) {
      //   teacherServeInfoList.value = serveList
      // } else {
      //   let arr = serveList.filter(ele => ele.serveKind == value)
      //   // console.log(arr);
      //   teacherServeInfoList.value = arr
      // }
    }

    watch(pageDraw, (n) => {
      if (n != null) {
        let width = n.offsetWidth - 10
        popoverWidth.value = width
      }
    }, {
      deep: true,
      immediate: true
    })

    const currentTeacherLvid = ref(-1)
    const getTeacherServeList = (tid) => {
      // console.log(4444, getTeacherServeList)
      store.dispatch('reserve/getTeacherServeListAction',{userId:tid,shopId:reserveForm.shopId})
    }

    const handleOptTeacherClick = item => {
      currentOptTeacherName.value = item.userRealname
      currentTeacherLvid.value = item.lvid
    }

    const handleTeacherChange = (value) => {
      serveNameArr.value = []
      reserveForm.reserveArr =  []
      nextTick(() => {
        getUserTimeListFn()
        getTeacherServeList(value)
        emitter.emit('emptyReserveOptProjectList')
      })
    }

    const getShopRoomListFunc = async () => {
      // const res = await getShopRoomList(props.reserve.shopId)
      // let list = res.data.list
      // shopRoomList.value = list
      getBedListByApp(props.reserve.id).then(res=>{
        // canRooms.value = res.data
        //debugger
        shopRoomList.value = res.data.map(t=>({
          ...t,
          enabled: t.bedList.some(b=>b.enabled),
          roomId: t.roomId == 0 ? '' : String(t.roomId)
        }))
        if (shopRoomList.value.length > 0) {
          handleRoomChange(shopRoomList.value[0].roomId)
        }
      })
    }
    const getShopRoomListFunc2 = async (roomId,bed) => {
      getBedListByApp(props.reserve.id).then(res=>{
        shopRoomList.value = res.data.map(t=>({
          ...t,
          enabled: t.bedList.some(b=>b.enabled),
          roomId: t.roomId == 0 ? '' : String(t.roomId)
        }))
        if (shopRoomList.value.length > 0) {
          const ele = shopRoomList.value.find(ele => ele.roomId == roomId)
          if (ele != undefined) {
            let arr = []
            let bedArr = ele.bedList
            for (let i = 0; i < bedArr.length; i++) {
              arr.push({
                ...bedArr[i],
                bedId:String(bedArr[i].bedId)
              })
            }
            ruleForm.bed=bed
            roomBedList.value = arr
          }
        }
      })
    }

    const getUserTimeListFn = async ()=>{
      const res = await getUserTimeList({
        "empId": reserveForm.empId,
        "date": ruleForm.arriveDate,
        "appId":  reserveInfo.value.id,
        "serveTimeLength":reserveInfo.value.serveTimeLength,
        "prepare":ruleForm.prepare,
        "shopId":reserveForm.shopId
      })
      let list = res.data
      userTimeList.value = list
    }
    // const handleServeTimeChange = async(e) => {
    //   if (!e) return
    //   ruleForm.arriveTime=""
    //   const res = await getUserTimeList({
    //     "empId": reserveForm.empId,
    //     "date": ruleForm.arriveDate,
    //     "appId":  reserveInfo.value.id,
    //     "serveTimeLength":e
    //   })
    //   let list = res.data
    //   userTimeList.value = list
    // }
    const handleRoomBedChange = async (value) => {
      let { orderNo, reserveId } = reserveForm
      let { roomId, bed } = ruleForm
      // console.log(orderNo, reserveId);
      // console.log(roomId, bedId);
      // const res = await updateReserveRoomBed({
      //   orderNo,
      //   id: reserveId,
      //   roomId,
      //   bedId
      // })
      // if (res.code == 0) {
      //   ElMessage({
      //     message: '操作成功!',
      //     type: 'success',
      //   })
      // }
    }



    const initPage = async () => {
      getUserTimeListFn()
      getShopRoomListFunc()
      store.dispatch('reserve/getTeacherListAction')
      // console.log('props.reserve', props.reserve)
      // store.dispatch('reserve/getReserveRemarkListAction', props.reserve.orderlist[0].subOrderNo)
      store.dispatch('reserve/getCustomerInfoListAction', props.reserve.userId)
      // debugger
      // const teaRes = await getReserveShopList(props.reserve.shopId)
      // let teacherList = teaRes.data.list
      // teacherList = teacherList.filter(ele => ele.status == 1)
      // shopTeacherList.value = teacherList
      // if (reserveInfo.value.appStatus >= 2) {
      //   const res = await getOrderDetail(COMPANY_ID, props.reserve.orderlist[0].subOrderNo)
      //   let detail = res.data.roomInfo
      //   ruleForm.roomId =  detail.roomId==0 ? '' : String(detail.roomId)
      //   ruleForm.bedId = detail.bedId
      // }
    }
    watch(() => props.reserve, async(n) => {
      if (n != null && n != '') {
        let adminInfo = cache.getCache('adminInfo')
        let {
          userId,
          shopId,
          arriveDate,
          arriveTime,
          empId,
          userMobile,
          arriveKind,
          timelist,
          id,
          bed,
          roomId,
          empName,
          appTimeArr,
          itemList,
          date,
          time,
          remark,
          appStatus,
          prepare
        } = n
        let newTimeArr = []
        serveNameArr.value = itemList
        try {
          updateProjectInfo.lastTime = timelist[timelist.length - 1]['arriveTime']
          timelist.forEach(ele => {
            newTimeArr.push(ele.arriveTime)
          })
        } catch (error) {
        }

        updateProjectInfo.oldTimeList = newTimeArr
        updateProjectInfo.updateTimeArr = appTimeArr
        reserveForm.userId = userId
        reserveForm.shopId = shopId
        reserveForm.arriveDate = arriveDate
        reserveForm.arriveTime = arriveTime
        reserveForm.empId = empId
        reserveForm.userMobile = userMobile
        reserveForm.arriveKind = arriveKind
        reserveForm.reserveId = id
        ruleForm.cashUid = adminInfo.userId
        currentOptTeacherName.value = empName
        ruleForm.arriveDate = date
        ruleForm.teacxherId = String(empId)
        ruleForm.arriveTime = time
        ruleForm.remark = remark
        ruleForm.prepare = prepare
        initPage()
        // if (appStatus > 1) {
          if (roomId) getShopRoomListFunc2(roomId,bed)
          ruleForm.roomId = roomId?String(roomId):''
          ruleForm.bed = bed||''
        // }

        store.dispatch('reserve/getShopTeacherListAction', shopId)
        getTeacherServeList(empId)
      }
    }, {
      immediate: true
    })

    onUnmounted(() => {
      emitter.off('refreshReserveCustomerListContent')
      emitter.off('refreshReserveCustomerListContent')
      emitter.off('changeReserveEditDrawPageContent')
    })


    return {
      userTimeList,
      timeStart,
      updateProjectInfo,
      changeTimeCount,
      handleSaveClick,
      handleTimeChange,
      isFirstRoomChange,
      handleRoomBedChange,
      handleTeacherChange,
      handleOptTeacherClick,
      shopTeacherList,
      currentTeacherLvid,
      getTeacherServeList,
      pageDraw,
      teacherServeCategoryList,
      getServeCatewgory,
      handleServeTypeChange,
      popoverWidth,
      handleOptProjectChange,
      reserveForm,
      currentOptTeacherName,
      handleUpdateReserveProject,
      handleRemoveProject,
      handleAddReserveClick,
      addInfo,
      currentStaffIndex,
      changeAddReserveDialog,
      addReserveDialogVisible,
      disabledDate,
      roomBedList,
      handleRoomChange,
      handleCancelReserve,
      handleRemoveItemClick,
      reserveRemarkList,
      handleSubmitClick,
      ruleForm,
      pageDialogVisible,
      changedDrawer,
      UPLOAD_BASE_URL,
      categoryProps,
      handleEditClick,
      dayjs,
      shopRoomList,
      teacherList,
      reserveInfo,
      customerInfoList,
      serveNameArr,
      handleReserveDetailcheck,
      handleUserDetailcheck,
      totalTimeList,
      totalTimeList2,
      loading
      // handleServeTimeChange
    }

  }
})
</script>

<style scoped lang="less">
.reserve-edit-draw {
  .ca-tag1 {
    &:deep(.el-tag) {
      border-color: rgb(121, 72, 234);
      color: rgb(121, 72, 234);
    }
  }

  .ca-tag2 {
    &:deep(.el-tag) {
      border-color: rgb(36, 153, 84);
      color: rgb(36, 153, 84);
    }
  }

  .ca-tag3 {
    &:deep(.el-tag) {
      border-color: rgb(19, 194, 194);
      color: rgb(19, 194, 194);
    }
  }

  .save-btn {
    display: flex;
    justify-content: center;
    margin-top: 14px;
  }

  .opt-teacher {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    margin: 4px 0;

    .key {
      font-size: 12px;
      color: rgb(128, 128, 128);
    }
  }

  &:deep(.el-radio-group) {
    .el-radio__label {
      font-size: 12px;
    }
  }

  &:deep(.border-divider) {
    margin: 10px 0 !important;
  }

  .edit-draw-content {
    padding-right: 50px;
  }
  &:deep(.el-dialog){
    margin-top: 8vh !important;
  }
  &:deep(.el-dialog__body){
    padding-top: 10px !important;
  }
  &:deep(.el-dialog__header){
    padding-top: 0px !important;
  }

  .titles {
    display: flex;
    line-height: 46px;
    background-color: rgb(239, 239, 239);
    border-radius: 6px;
    padding: 0 16px;
    font-size: 12px;

    .comment {
      flex: 1;
    }

    .teacher {
      width: 120px;
    }
  }

  .title {
    display: flex;
    position: relative;
    justify-content: space-between;
    padding-left: 16px;
    color: rgba(80, 80, 80, 1);
    font-size: 12px;
    font-weight: 550;
    margin: 10px 0 10px 0;

    .other {
      color: rgba(42, 130, 228, 1);

      span {
        margin: 0 6px;
        cursor: pointer;
      }
    }

    &::before {
      content: '';
      position: absolute;
      width: 4px;
      height: 20px;
      background-color: rgba(42, 130, 228, 1);
      left: 0;
      top: -3px;
    }
  }

  .follow {
    margin-top: 10px;
    padding-left: 10px;

    .submin-btn {
      text-align: right;
      margin: 10px 0;
    }

    .list {
      .list-item {
        padding: 0 10px;

        .follow-item {
          padding: 10px 0;
          border-bottom: 1px solid rgba(176, 170, 187, 0.5);
          font-size: 12px;

          .top {
            display: flex;
            padding: 0 10px;

            .left {
              flex: 1;
              padding-left: 2px;
            }

            .right {
              width: 120px;
              padding-left: 7px;
            }
          }

          .bottom {
            display: flex;
            padding-left: 4px;
            margin-top: 16px;
            padding: 0 10px;

            .order-num {
              color: rgb(102, 177, 255);
              white-space: nowrap;
            }

            & > div {
              flex: 1;
              display: flex;
              justify-content: space-between;

              .remove {
                color: rgb(64, 158, 255);
                font-size: 12px;
                cursor: pointer;
              }
            }
          }

          .bottom {
            margin-top: 16px;
            color: rgb(176, 170, 187);
            font-size: 12px;
          }
        }
      }
    }
  }

  .remark {
    margin-top: 10px;

    .submin-btn {
      text-align: right;
      margin-top: 10px;
    }

    .list {
      .list-item {
        .item {
          display: flex;
          justify-content: space-between;
          margin: 10px 0;

          .right {
            display: flex;
          }

          .memo {
            margin-bottom: 6px;
          }

          .time {
            font-size: 12px;
            color: rgb(176, 170, 187);
            display: flex;
          }

          div {
            flex: 1;
          }
        }
      }
    }
  }

  .serve-info {
    margin-top: 10px;
    padding-left: 10px;

    .add-serve {
      margin: 10px 0;
      text-align: right;
    }

    .item {
      display: flex;
      align-items: center;
      border-radius: 4px;
      padding: 0px 10px 10px 10px;
      border-bottom: 1px solid rgb(227, 227, 227);
      margin: 10px 0 0;
      justify-content: flex-start;
      .serveTimeLength {
        width: 120px;
      }

      .handle {
        width: 80px;
        color: rgb(64, 158, 255);
        cursor: pointer;
      }

      .key {
        display: flex;
        width: 200px;
        margin-left: 6px;

        .serve-item-info {
          border-radius: 4px;
          overflow: hidden;
          display: flex;
          width: 80%;
          padding: 10px;
          border: 1px solid rgb(222, 222, 222);

          .left {
            flex: 7;

            .serveName {
              color: rgba(80, 80, 80, 1);
              font-size: 12px;
              margin-bottom: 6px;
            }

            .serveLength {
              color: rgba(166, 166, 166, 1);
              font-size: 12px;
            }
          }

          .right {
            flex: 1;
            display: flex;
            align-items: center;
          }
        }
      }

      .value {
        width: 130px;
        // position: relative;
        // left: -2px;
      }
    }

    .titles {
      background-color: rgb(239, 239, 239);
      display: flex;
      // justify-content: space-between;
      line-height: 46px;
      border-radius: 6px;

      .time {
        width: 120px;
      }
      .handle {
        width: 80px;
      }

      .teacher {
        width: 130px;
      }

      .project {
        width: 200px;
      }
    }
  }

  &:deep(.el-input) {
    min-width: 140px;
    max-width: 200px;
  }

  .item {
    display: flex;
    margin: 10px 0;
    justify-content: space-between;
    font-size: 12px;

    .key {
      color: rgba(128, 128, 128, 1);
    }

    .value {
      color: rgba(80, 80, 80, 1);
    }
  }

  .shop-info {
    padding-left: 10px;

    .item {
      .value {
        display: flex;
      }
    }

    &:deep(.el-input) {
      margin-left: 10px;
      max-width: 150px;
    }

    &:deep(.el-select) {
    }
  }

  .reserve-people {
    padding-left: 10px;

    .item {
      display: flex;
      justify-content: space-between;
      margin: 10px 0px;

      .key {
        width: 100px;
        color: rgba(128, 128, 128, 1);
      }

      .value {
        justify-content: flex-end;
        flex: 1;
        display: flex;
        color: rgba(80, 80, 80, 1);

        .uname {
          margin-right: 10px;
        }

        &:deep(.el-input) {
          max-width: 200px;
          margin-left: 10px;
        }
      }
    }
  }

  .reserve-time {
    border-radius: 10px;
    height: 48px;
    background-color: rgb(239, 239, 239);
    margin: 10px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;

    .left {
      display: flex;
      align-items: center;
      color: rgba(255, 87, 51, 1);
      font-size: 12px;

      img {
        width: 20px;
        margin-right: 12px;
      }
    }

    .right {
    }
  }

  .top-form {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(223, 223, 223);
    width: 93%;

    .left,
    .right {
      display: flex;
      align-items: center;
    }

    .left {
      font-weight: 550;
    }

    .right {
      display: flex;

      .sure {
      }
    }
  }
  &:deep(.el-input-number.is-without-controls .el-input__inner){
    text-align: left;
  }
}
</style>
